.container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.walletDetails,
.createWallet {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input,
.select {
  margin-bottom: 20px;
  width: 100%;
}

.button {
  margin-bottom: 20px;
}

.link {
  margin-bottom: 20px;
  text-decoration: none;
}
