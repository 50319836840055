.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
